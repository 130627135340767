/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
}

.bgcred, .bgcred_h:hover { background-color: #d10e0e; }
.bgcred_i, .bgcred_h_i:hover { background-color: #d10e0e !important; }
.bgcgreen, .bgcgreen_h:hover { background-color: #35a000; }
.bgcgreen_i, .bgcgreen_h_i:hover { background-color: #35a000 !important; }

label .required, .cred, .cred_h:hover { color: #d10e0e; }
.cred_i, .cred_h_i:hover { color: #d10e0e !important; }
.cgreen, .cgreen_h:hover { color: #35a000; }
.cgreen_i, .cgreen_h_i:hover { color: #35a000 !important; }

.brcred, .brcred_h:hover { border-color: #d10e0e; }
.brcred_i, .brcred_h_i:hover { border-color: #d10e0e !important; }
.brcgreen, .brcgreen_h:hover { border-color: #35a000; }
.brcgreen_i, .brcgreen_h_i:hover { border-color: #35a000 !important; }


p { margin-bottom: 20px; text-align: justify; }
p + p { margin-top: -6px; }


.link {
  color: #d10e0e;
  text-decoration: underline;
  cursor: pointer;
}
  .link[href]:hover {
    text-decoration: none;
  }


.form-popup-container {
  background: #fff;
  padding: 10px 30px 30px;
  min-width: 650px;
}

.form {
  position: relative;
}
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
    .form-row + .form-row,
    .form-buttons {
      margin-top: 20px;
    }
      .form-label {
        width: 210px;
        min-width: 210px;
        margin-top: 11px;
      }
        .form-label label .required {
          font-weight: 700;
        }

      .form-field {
        width: 100%;
      }
        .input-container {
          background-color: #fff;
          border: 1px solid #e8e8e8;
          padding: 9px 15px 8px;
          position: relative;
          z-index: 1;
        }
          .input-container.error {
            border-color: #d10e0e;
          }

          .input-container input,
          .input-container textarea,
          .input-container select {
            color: #222;
            background: transparent;
            font-size: 0.88888889em;
            font-weight: 600;
            line-height: 1.3;
            border: 0px;
            padding: 0px;
            margin: 0px;
          }
          .input-container input:not([type="radio"]):not([type="checkbox"]),
          .input-container textarea,
          .input-container select {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }

            .input-container input[placeholder] { color: #222; opacity: 1; }
            .input-container input::-webkit-input-placeholder { color: #222; opacity: 1; }
            .input-container input::-moz-placeholder { color: #222; opacity: 1; }
            .input-container input:-moz-placeholder { color: #222; opacity: 1; }
            .input-container input:-ms-input-placeholder { color: #222; opacity: 1; }


        .form-error {
          position: relative;
        }
          .errorMessage {
            background-color: #d10e0e;
            padding: 3px 6px 4px;
            margin: 0px;
            color: #fff;
            font-size: 13px;
            font-weight: 500;
            line-height: 1.3;
          }

  .form-button {
    display: inline-block;
  }


.button-style {
  display: inline-block;
  font-size: 1em;
  font-weight: 600;
  line-height: 1.3;
  text-align: center;
  background: none;
  border: 0.16666667em solid #d10e0e;
  border-radius: 999px;
  cursor: pointer;
  min-width: 240px;
  padding: 0.5em 1.6em;
  margin: 0px;
  position: relative;
}
  .button-style:hover,
  .button-style.btn-filled {
    color: #fff;
    background-color: #d10e0e;
  }

  .button-style.btn-white {
    color: #fff;
    border-color: #fff;
  }
    .button-style.btn-white:hover,
    .button-style.btn-white.btn-filled {
      color: #222;
      background-color: #fff;
    }

  .button-style.btn-green {
    color: #35a000;
    border-color: #35a000;
  }
    .button-style.btn-green:hover,
    .button-style.btn-green.btn-filled {
      color: #fff;
      background-color: #35a000;
    }


ul, ol { list-style: none outside; }
  ul.ul, ol.ol {
    margin-top: -6px;
    margin-bottom: 20px;
  }
  ol.ol { counter-reset: ol-list-counter; }
    ul.ul ul.ul, ol.ol ol.ol {
      padding-left: 20px;
      margin-top: 0px;
      margin-bottom: 2px;
    }
    ul.ul li, ol.ol li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;

      padding-top: 6px;
      padding-bottom: 0px;
      position: relative;
    }
      ul.ul li:before {
        content: "●";
        display: inline-block;
        background: url('/i/ul-list-style-image.svg') no-repeat 50% 50% transparent;
        background-size: contain;
        font-weight: 600;
        margin-right: 0.6em;
        width: 0.6em;
        color: transparent;
      }
      ol.ol li:before {
        content: counters(ol-list-counter, ".") ". ";
        counter-increment: ol-list-counter;
        font-weight: 600;
        color: #d10e0e;
      }

      ul.ul li > div,
      ul.ul li > div * {
        font-size: inherit;
        font-weight: 500;
      }


a.zoom-image {
  display: inline-block;
  position: relative;
}
  a.zoom-image:before,
  a.zoom-image:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;

    -webkit-transition: opacity 0.3s ease 0s;
    -moz-transition: opacity 0.3s ease 0s;
    -o-transition: opacity 0.3s ease 0s;
    transition: opacity 0.3s ease 0s;
  }

  a.zoom-image:before {
    background: #6c0000;
    mix-blend-mode: multiply;
  }
    a.zoom-image:hover:before {
      opacity: 0.7;
    }

  a.zoom-image:after {
    background: url('/i/gallery-image-zoom.svg') 50% 50% no-repeat transparent;
    background-size: contain;
    margin: auto;
    width: 18%;
    height: 18%;
    z-index: 1;
  }
    a.zoom-image:hover:after {
      opacity: 1;
    }


.wrapper {
  width: 1240px;
  position: relative;
  margin: 0px auto;
  padding: 0px;
}
  .unwrapper {
    margin-left: -9999px;
    margin-right: -9999px;
    position: static;
  }



.structure { overflow: hidden; }



/* Header */
.header {
  background: #fff;
  position: relative;
  z-index: 5;
}
  /*.header.is-fixed {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
  }*/

  .header-advantages {
    overflow: hidden;
  }
    .header-advantages ul {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: flex-end;
      align-items: flex-start;
      margin: 0px 0px 0px -15px;
      padding: 4px 0px 0px;
    }
      .header-advantages ul li {
        font-size: 14px;
        padding-top: 6px;
        padding-left: 15px;
      }


  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;

    -webkit-transition: padding 0.3s ease 0s;
    -moz-transition: padding 0.3s ease 0s;
    -o-transition: padding 0.3s ease 0s;
    transition: padding 0.3s ease 0s;
  }
    /*.header.is-fixed .header-content {
      padding: 10px 0px 8px;
    }*/
    .header-logo a,
    .header-logo a * {
      cursor: pointer;
    }

    .header-contact {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
      .header-phones-container {
        padding-left: 30px;
      }
        .header-phones > div {
          padding: 4px 0px;
        }
          .header-phones a {
            font-size: 30px;
            font-weight: 700;
          }

    .header-socials {
      padding-left: 30px;
    }
      .header-socials > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        margin: -10px 0px 0px -10px;
      }
        .header-socials a {
          content: '';
          background: no-repeat 50% 50%/contain transparent;
          font-size: 0px;
          min-width: 36px;
          margin: 10px 0px 0px 10px;
        }
          .header-socials a:before {
            content: '';
            display: block;
            height: 0px;
            padding-top: 100%;
          }


  .header-menu-container {
    padding-bottom: 1px;
    position: relative;
  }
    .header-menu-container:before {
      content: "";
      position: absolute;
      background: #eee;
      left: -9999px;
      right: -9999px;
      bottom: 0px;
      height: 1px;
    }
    .header-menu-container > ul {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0px -10px;
    }
      .header-menu-container > ul > li {
        position: relative;
      }
        .header-menu-container > ul > li > div > div > a,
        .header-menu-container > ul > li > div > div > span {
          display: block;
          font-size: 22px;
          font-weight: 600;
          text-transform: uppercase;
          white-space: nowrap;
          padding: 0px 20px 9px;
          margin: 0px -10px;
          text-align: center;
          position: relative;
          overflow: hidden;

          -webkit-transition: color 0.2s ease 0s;
          -moz-transition: color 0.2s ease 0s;
          -o-transition: color 0.2s ease 0s;
          transition: color 0.2s ease 0s;
        }
          .header-menu-container > ul > li.active > div > div > a,
          .header-menu-container > ul > li.active > div > div > span {
            cursor: default;
          }
          .header-menu-container > ul > li:hover > div > div > a,
          .header-menu-container > ul > li:hover > div > div > span,
          .header-menu-container > ul > li.active > div > div > a,
          .header-menu-container > ul > li.active > div > div > span {
            color: #d10e0e;
          }
          .header-menu-container > ul > li > div > div > a:after,
          .header-menu-container > ul > li > div > div > span:after {
            content: "";
            background: #d10e0e;
            display: block;
            position: absolute;
            left: 10px;
            right: 10px;
            bottom: -12px;
            height: 13px;
            border-radius: 50%;
            opacity: 0;

            -webkit-filter: blur(10px);
            filter: blur(10px);

            -webkit-transition: opacity 0.2s ease 0s;
            -moz-transition: opacity 0.2s ease 0s;
            -o-transition: opacity 0.2s ease 0s;
            transition: opacity 0.2s ease 0s;
          }
            .header-menu-container > ul > li:hover > div > div > a:after,
            .header-menu-container > ul > li:hover > div > div > span:after,
            .header-menu-container > ul > li.active > div > div > a:after,
            .header-menu-container > ul > li.active > div > div > span:after {
              opacity: 0.5;
            }

        .header-menu-container > ul > li > ul {
          /*display: none;*/
          position: absolute;
          top: 100%;
          left: 10px;
          min-width: 100%;
          /*max-width: 1240px;*/
          padding: 20px 0px 25px;

          visibility: hidden;
          opacity: 0;

          -webkit-transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
          -moz-transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
          -o-transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
          transition: opacity 0.3s ease 0s, visibility 0s ease 0.3s;
        }
          .header-menu-container > ul > li:hover > ul {
            visibility: visible;
            opacity: 1;

            -webkit-transition: opacity 0.3s ease 0s, visibility 0s ease 0s;
            -moz-transition: opacity 0.3s ease 0s, visibility 0s ease 0s;
            -o-transition: opacity 0.3s ease 0s, visibility 0s ease 0s;
            transition: opacity 0.3s ease 0s, visibility 0s ease 0s;
          }
          .header-menu-container > ul > li > ul:before {
            content: "";
            position: absolute;
            top: 5px;
            left: -21px;
            right: -21px;
            bottom: 0px;
            box-shadow: 0px 2px 10px 0px #e5e5e5;
            z-index: 0;
          }
          .header-menu-container > ul > li > ul:after {
            content: "";
            background: #fff;
            position: absolute;
            top: 0px;
            left: -25px;
            right: -25px;
            bottom: 0px;
            border: 1px solid #f5f5f5;
            border-bottom-color: #e8e8e8;
            z-index: 1;
          }
          .header-menu-container > ul > li > ul > li {
            position: relative;
            z-index: 2;
          }
            .header-menu-container > ul > li > ul > li a {
              display: block;
              font-size: 16px;
              font-weight: 500;
              white-space: nowrap;
              padding: 5px 0px;
              cursor: pointer;

              -webkit-transition: color 0.3s ease 0s;
              -moz-transition: color 0.3s ease 0s;
              -o-transition: color 0.3s ease 0s;
              transition: color 0.3s ease 0s;
            }
              .header-menu-container > ul > li > ul li:hover > div > div > a,
              .header-menu-container > ul > li > ul li.active > div > div > a {
                color: #d10e0e;
              }
              .header-menu-container > ul > li > ul li.active > div > div > a {
                cursor: default;
              }


          .header-menu-container > ul > li.icn-menu-service1 > ul {
            font-size: 0px;
            width: 1240px;
            padding-top: 0px;
          }
            .header-menu-container > ul > li.icn-menu-service1 > ul > li {
              display: inline-block;
              box-sizing: content-box;
              width: 232px;
              padding: 20px 0px 0px 20px;
            }
              .header-menu-container > ul > li.icn-menu-service1 > ul > li:nth-child(5n+1) {
                padding-left: 0px !important;
              }

              .header-menu-container > ul > li.icn-menu-service1 > ul > li a {
                white-space: normal;
              }
              .header-menu-container > ul > li.icn-menu-service1 > ul > li > div > div > a,
              .header-menu-container > ul > li.icn-menu-service1 > ul > li > div > div > span {
                color: #d10e0e;
                font-size: 18px;
                font-weight: 700;
                text-transform: uppercase;
              }
              .header-menu-container > ul > li.icn-menu-service1 > ul > li > div > div > a {
                text-decoration: underline;
              }
                .header-menu-container > ul > li.icn-menu-service1 > ul > li.active > div > div > a,
                .header-menu-container > ul > li.icn-menu-service1 > ul > li > div > div > a:hover {
                  text-decoration: none;
                }
                .header-menu-container > ul > li.icn-menu-service1 > ul > li.active > div > div > a {
                  color: #222;
                }



/* Content block */
.content {
  padding: 0px 0px 10px;
  position: relative;
  z-index: 3;

  /*min-height: 3000px;*/
}
  .main-rotator-block-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px 0px 25px;
    overflow: hidden;
    position: relative;
    z-index: 1;
  }
    .main-rotator {
      width: 100%;
      height: 500px;
      overflow: hidden;
      order: 1;
    }
      #main-rotator-dots {
        position: absolute;
        left: 10px;
        right: 0px;
        bottom: 30px;
        z-index: 3;
      }
        #main-rotator-dots ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: flex-start;
        }
          #main-rotator-dots li {
            background: #fff;
            width: 20px;
            height: 20px;
            margin: 20px 0px 0px 20px;
            border-radius: 50%;
            cursor: pointer;

            -webkit-transition: background 0.3s ease 0s;
            -moz-transition: background 0.3s ease 0s;
            -o-transition: background 0.3s ease 0s;
            transition: background 0.3s ease 0s;
          }
            #main-rotator-dots li.slick-active {
              background: #d10e0e;
            }

      .main-rotator-slide {
        position: relative;
      }
        .main-rotator-slide-link,
        .main-mini-slide-link {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 3;
        }
          .main-rotator-slide-link a,
          .main-mini-slide-link a {
            content: "";
            font-size: 0px;
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            cursor: pointer;
          }

        .main-rotator-slide-image,
        .main-mini-slide-image {
          background-color: #eee;
          background-position: 50% 0%;
          background-repeat: no-repeat;
          background-size: cover;
          height: 500px;
          position: relative;
          z-index: 1;
        }

        .main-rotator-slide-code,
        .main-mini-slide-code {
          background: rgba(0,0,0,.4);
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          padding: 35px 30px 80px;
          z-index: 2;
        }


    .main-mini-slides {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      min-width: 440px;
      max-width: 440px;
      order: 2;
    }
      .main-mini-slides > div {
        position: relative;
        overflow: hidden;
      }
        .main-mini-slide-image {
          height: 250px;
        }

        .main-mini-slide-code {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: stretch;
          background: none;
          padding: 35px;
        }


  .breadcrumbs {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: flex-start;
    font-size: 12px;
    padding: 25px 0px 10px;
  }
    .breadcrumbs li {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }
      .breadcrumbs li:before,
      .breadcrumbs a,
      .breadcrumbs span {
        vertical-align: baseline;
        line-height: 1.1;
      }
      .breadcrumbs li + li:before {
        content: "";
        align-self: stretch;
        background: url('/i/breadcrumbs-arrow.svg') no-repeat 50% 50% transparent;
        background-size: auto 70%;
        width: 20px;
        color: transparent;
      }
      .breadcrumbs a {
        color: #d10e0e;
        cursor: pointer;
        text-decoration: none;
        font-weight: 600;
      }
        .breadcrumbs a:hover {
          text-decoration: underline;
        }


  .subheader {
    position: relative;
    font-size: 36px;
    font-weight: 900;
    line-height: 1.3;
    text-transform: uppercase;
    padding: 15px 0px 20px;
    text-align: left;
    z-index: 2;
  }
    .subheader.subheader-center {
      text-align: center !important;
    }
    /*h1.subheader {
      font-size: 38px;
    }*/


  .text-container {
    padding: 0px 0px 30px;
    position: relative;
    z-index: 1;
  }


  .quick-link-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: center;
    padding: 0px 0px 25px;
    margin: -15px 0px 0px -15px;
    position: relative;
    z-index: 1;
  }
    .quick-link-list > div {
      font-weight: 600;
      line-height: 1.1;
      padding: 15px 0px 0px 15px;
    }
      .quick-link-list > div:not(:last-child):after {
        content: "/";
        display: inline-block;
        color: #d10e0e;
        padding-left: 15px;
        vertical-align: middle;
      }
      .quick-link-list a {
        color: #d10e0e;
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        border-bottom: 1px dashed #d10e0e;
        cursor: pointer;
      }
        .quick-link-list a:hover,
        .quick-link-list a.active {
          border-bottom-color: transparent;
        }
        .quick-link-list a.active {
          color: #222;
          cursor: default;
        }


  .incut {
    line-height: 1.3;
    padding: 20px 0px 10px 34px;
    margin: 0px 0px 25px;
    position: relative;
  }
    .incut,
    .incut * {
      font-weight: 600;
    }
    .incut:before {
      content: "";
      background: #d10e0e;
      position: absolute;
      top: 20px;
      left: 0px;
      bottom: 10px;
      width: 4px;
      z-index: -1;
    }

    .incut *:last-child {
      margin-bottom: 0px;
    }


  .why-we-block-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: center;
    padding: 0px 0px 5px;
    margin: -10px 0px 0px -190px;
  }
    .why-we-block-container > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 33.3333%;
      padding: 20px 0px 20px 190px;
      position: relative;
    }
      .why-we-block-container > div:nth-child(3n-1):before,
      .why-we-block-container > div:nth-child(3n):before {
        content: "";
        position: absolute;
        background: #aaa;
        top: 20px;
        left: 90px;
        bottom: 20px;
        margin: auto;
        width: 3px;
        height: 130px;
        border-radius: 3px;

        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .why-we-block-container .why-we-image {
        min-width: 110px;
        max-width: 110px;
        padding-right: 30px;
      }
        .why-we-block-container .why-we-image img {
          width: 100%;
        }

      .why-we-block-container .why-we-text {
        font-size: 30px;
        line-height: 1.3;
      }
        .why-we-block-container .why-we-text span {
          color: #d10e0e;
          font-size: 34px;
          font-weight: 900;
          line-height: 1.1;
          text-transform: uppercase;
        }


  .advantages1-block-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 5px;
    margin: -10px 0px 0px -15px;
  }
    .advantages1-block-container > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 25%;
      padding: 20px 0px 20px 15px;
      position: relative;
    }
      .advantages1-block-container .advantage-item-image {
        position: relative;
        overflow: hidden;
      }
        .advantages1-block-container .advantage-item-image:before {
          content: "";
          background: #07ad96;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          width: 110px;
          margin: auto;
          opacity: 0.35;
          z-index: 0;

          -webkit-transform: skewX(-45deg);
          -moz-transform: skewX(-45deg);
          -ms-transform: skewX(-45deg);
          -o-transform: skewX(-45deg);
          transform: skewX(-45deg);
        }
        .advantages1-block-container .advantage-item-image img {
          width: 130px;
          padding: 25px;
          box-sizing: content-box;
          position: relative;
          z-index: 1;
        }

      .advantages1-block-container .advantage-item-name {
        font-size: 28px;
        font-weight: 900;
        text-transform: uppercase;
        padding: 20px 0px 0px;
        text-align: center;
      }


  .advantages2-block-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 25px;
    margin: -20px 0px 0px;
  }
    .advantages2-block-container > div {
      width: 33.3333%;
      padding: 20px 20px 0px 35px;
      position: relative;
    }
      .advantages2-block-container .advantage-item-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
        color: #d10e0e;
        font-size: 28px;
        font-weight: 900;
        min-height: 73px;
        margin: 0px 0px 0px -35px;
      }
        .advantages2-block-container .advantage-item-title:before {
          content: "/";
          min-width: 35px;
          max-width: 35px;
        }
        .advantages2-block-container .advantage-item-title * {
          color: inherit;
          font-size: inherit;
          font-weight: inherit;
          line-height: 1.3;
          text-transform: uppercase;
        }

      .advantages2-block-container .advantage-item-text {
        padding: 10px 0px 0px;
      }
        .advantages2-block-container .advantage-item-text,
        .advantages2-block-container .advantage-item-text * {
          font-size: 16px;
          font-weight: 500;
        }


  .excursion-block-container {
    background: url('/f/upload/excursion-block-bg.jpg') 50% 50% no-repeat transparent;
    background-size: cover;
    height: 570px;
    padding: 75px 35px;
    margin: 0px 0px 25px;
    position: relative;
    overflow: hidden;
  }
    .excursion-block-container:after {
      content: "";
      position: absolute;
      right: -1px;
      bottom: -1px;
      border-top: 210px solid transparent;
      border-right: 210px solid #fff;
    }

    .excursion-block-text {
      display: inline-block;
      position: relative;
    }
      .excursion-block-text:before {
        content: "";
        position: absolute;
        background: #fff;
        top: -80px;
        left: -100px;
        right: -180px;
        bottom: -70px;
        border-radius: 60% 80% 100% 30%;
        opacity: 0.7;
        z-index: -1;

        -webkit-filter: blur(50px);
        filter: blur(50px);
      }


  .how-tow-work-block-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    padding: 0px 0px 5px;
    margin: -10px 0px 0px -100px;
  }
    .how-tow-work-block-container > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      padding: 20px 0px 20px 130px;
      position: relative;
    }
      .how-tow-work-block-container .how-tow-work-digit {
        color: #d10e0e;
        font-size: 80px;
        font-weight: 900;
        line-height: 1.1;
        min-width: 90px;
        max-width: 90px;
        padding-right: 15px;
        text-align: left;
      }

      .how-tow-work-block-container .how-tow-work-title {
        color: #d10e0e;
        font-size: 28px;
        font-weight: 900;
        text-align: left;
      }

      .how-tow-work-block-container .how-tow-work-text,
      .how-tow-work-block-container .how-tow-work-text * {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
      }


  .gallery-block-container > div {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;

    padding: 0px 0px 25px;
    margin: -8px 0px 0px -8px;
    position: relative;
  }
    .gallery-block-container .gallery-item {
      margin: 8px 0px 0px 8px;
      position: relative;
      overflow: hidden;
    }
      .gallery-block-container .gallery-item-notice {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 0px;
        padding: 15px 10px 10px;
      }
        .gallery-block-container .gallery-item-notice:before {
          content: "";
          background: #222;
          position: absolute;
          top: 0px;
          left: -20px;
          right: -20px;
          bottom: -20px;
          opacity: 0.7;
          z-index: -1;

          -webkit-filter: blur(10px);
          filter: blur(10px);
        }
        .gallery-block-container .gallery-item-notice,
        .gallery-block-container .gallery-item-notice * {
          font-size: 12px;
          color: #fff;
        }

    .gallery-block-container .gallery-notice {
      padding: 0px 0px 20px;
      position: relative;
    }


  .list-view,
  .portfolio-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: stretch;

    margin: -20px 0px 0px -20px;
    position: relative;
  }
  .list-view {
    padding: 0px 0px 25px;
  }
    .list-view-item {
      width: 25%;
      padding: 20px 0px 0px 20px;
      position: relative;
    }
      .list-view-item-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        height: 100%;
        position: relative;
      }
        .list-view-item-image {
          font-size: 0px;
          position: relative;
          order: 2;
          z-index: 1;
        }
          .list-view-item-image a,
          .list-view-item-image span {
            display: block;
            height: 0px;
            font-size: 0px;
            background-color: #eee;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            background-size: cover;
            padding-top: 75%;
          }
            .list-view-item-image a {
              cursor: pointer;
            }

        .list-view-item-text {
          padding: 15px 0px 25px;
          position: relative;
          order: 3;
          z-index: 2;
        }
          .list-view-item-name {
            position: relative;
            overflow: hidden;
            order: 1;
          }
            .list-view-item-name a,
            .list-view-item-name span {
              display: block;
              text-align: left;
              font-weight: 900;
              line-height: 1.3;
              text-transform: uppercase;
              text-decoration: none;
            }
              .list-view-item-name a {
                cursor: pointer;
              }

          .list-view-item-name + .list-view-item-notice {
            padding: 15px 0px 0px;
          }
            .list-view-item-notice * {
              font-size: 14px;
              text-align: left;
            }
            .list-view-item-notice *:last-child {
              margin-bottom: 0px;
            }


  .service-block-container .list-view-item {
    width: 504px;
  }
    .service-block-container .list-view-item:nth-child(4n-2),
    .service-block-container .list-view-item:nth-child(4n-2) + .list-view-item {
      width: 756px;
    }
    .service-block-container .list-view-item-image,
    .portfolio-block-container .list-view-item-image,
    .price-vitrine-container .list-view-item-image {
      overflow: hidden;
    }
      .service-block-container .list-view-item-image:after,
      .portfolio-block-container .list-view-item-image:after,
      .price-vitrine-container .list-view-item-image:after {
        content: "";
        position: absolute;
        top: 0px;
        left: -149px;
        border-top: 335px solid #6c0000;
        border-right: 335px solid transparent;
        padding-left: 149px;
        opacity: 0.7;

        mix-blend-mode: multiply;
        z-index: 1;

        -webkit-transition: left 0.3s ease 0s;
        -moz-transition: left 0.3s ease 0s;
        -o-transition: left 0.3s ease 0s;
        transition: left 0.3s ease 0s;
      }
        .service-block-container .list-view-item-wrapper:hover .list-view-item-image:after,
        .portfolio-block-container .list-view-item-wrapper:hover .list-view-item-image:after,
        .price-vitrine-container .list-view-item-wrapper:hover .list-view-item-image:after {
          left: 0px;
        }

      .service-block-container .list-view-item-image a,
      .service-block-container .list-view-item-image span {
        height: 335px;
        padding-top: 0px;
      }

    .service-block-container .list-view-item-text,
    .portfolio-block-container .list-view-item-text,
    .price-vitrine-container .list-view-item-text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 30px 30px 20px;
      overflow: hidden;
    }
      .service-block-container .list-view-item-name a,
      .service-block-container .list-view-item-name span {
        font-size: 34px;
        color: #fff;
        height: 133px; /* 89px */
        max-width: 95%;
        text-shadow: 3px 4px 4px rgba(0,0,0,.16);
      }

      .service-block-container .list-view-item-button,
      .portfolio-block-container .list-view-item-button {
        padding-top: 25px;
        margin-left: -30px;
        opacity: 0;
        order: 2;

        -webkit-transition: margin-left 0.3s ease 0s, opacity 0.3s ease 0s;
        -moz-transition: margin-left 0.3s ease 0s, opacity 0.3s ease 0s;
        -o-transition: margin-left 0.3s ease 0s, opacity 0.3s ease 0s;
        transition: margin-left 0.3s ease 0s, opacity 0.3s ease 0s;
      }
        .service-block-container .list-view-item-wrapper:hover .list-view-item-button,
        .portfolio-block-container .list-view-item-wrapper:hover .list-view-item-button {
          margin-left: 0px;
          opacity: 1;
        }
        /*.service-block-container .list-view-item-button .button-style *,
        .portfolio-block-container .list-view-item-button .button-style * {
          color: #fff;
        }
          .service-block-container .list-view-item-button .button-style:hover *,
          .portfolio-block-container .list-view-item-button .button-style:hover * {
            color: #000;
          }*/

      .service-block-container .list-view-item-notice {
        align-self: flex-end;
        margin: 0px -30px -20px 0px;
        padding: 0px 30px 20px 0px;
        max-width: none;
        position: relative;
        order: 3;
      }
        .service-block-container .list-view-item:nth-child(4n-2) .list-view-item-notice,
        .service-block-container .list-view-item:nth-child(4n-2) + .list-view-item .list-view-item-notice {
          max-width: 85%;
        }
        .service-block-container .list-view-item-notice * {
          color: #fff;
          font-weight: 600;
          text-align: right;
          position: relative;
          z-index: 1;
        }
        .service-block-container .list-view-item-notice:after {
          content: "";
          position: absolute;
          background: #000;
          top: -20px;
          left: -10px;
          right: -50px;
          bottom: -50px;
          border-radius: 60% 0px 0px;
          z-index: 0;
          opacity: 0.55;

          -webkit-filter: blur(20px);
          filter: blur(20px);
        }


  .price-vitrine-container .list-view-item {
    width: 315px;
  }
    .price-vitrine-container .list-view-item-image:after {
      left: 0px;
      border-top-width: 160px;
      border-right-width: 160px;
      padding-left: 135px;
    }

    .price-vitrine-container .list-view-item-text {
      padding: 20px 25px;
    }
      .price-vitrine-container .list-view-item-image span {
        height: 160px;
        padding-top: 0px;
      }

      .price-vitrine-container .list-view-item-name span {
        font-size: 22px;
        color: #fff;
        height: 58px;
        text-shadow: 3px 4px 4px rgba(0,0,0,.16);
      }

      .price-vitrine-container .price-vitrine-item-price {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        padding-top: 20px;
        vertical-align: baseline;
        order: 2;
      }
        .price-vitrine-container .price-vitrine-item-price * {
          color: #fff;
          font-size: 18px;
          font-weight: 500;
          vertical-align: baseline;
        }


  .portfolio-block-container .list-view {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    margin: 0px;
  }
    .portfolio-block-container .list-view-item {
      width: 420px;
    }
      .portfolio-block-container .list-view-item-image:after {
        left: -130px;
        border-top-width: 270px;
        border-right-width: 270px;
        padding-left: 130px;
      }

      .portfolio-block-container .list-view-item-image a {
        height: 270px;
        padding-top: 0px;
      }

      .portfolio-block-container .list-view-item-name a {
        font-size: 26px;
        color: #fff;
        height: 102px;
        text-shadow: 3px 4px 4px rgba(0,0,0,.16);
      }


    .team-block-container .list-view-item {
      width: 252px;
    }
      .team-block-container .list-view-item-image:before {
        top: -1px;
        right: -1px;
        border-top: 61px solid #fff;
        border-left: 61px solid transparent;
      }

      .team-block-container .list-view-item-image span {
        height: 240px;
        padding-top: 0px;
      }

      .team-block-container .list-view-item-text {
        padding-bottom: 0px;
      }


    .discount-block-container .list-view-item-image:before,
    .discount-block-container .list-view-item-image:after,
    .team-block-container .list-view-item-image:before,
    .news-block-container .list-view-item-image:before,
    .news-block-container .list-view-item-image:after {
      content: "";
      position: absolute;
      z-index: 1;
    }
    .discount-block-container .list-view-item-image:before,
    .news-block-container .list-view-item-image:before {
      top: -1px;
      left: -1px;
      border-left: 61px solid #fff;
      border-bottom: 61px solid transparent;
    }
    .discount-block-container .list-view-item-image:after,
    .news-block-container .list-view-item-image:after {
      right: -1px;
      bottom: -1px;
      border-top: 61px solid transparent;
      border-right: 61px solid #fff;
    }

    .discount-block-container .list-view-item-image a,
    .discount-block-container .list-view-item-image span,
    .news-block-container .list-view-item-image a,
    .news-block-container .list-view-item-image span {
      height: 225px;
      padding-top: 0px;
    }

    .discount-block-container .list-view-item-name a:hover,
    .discount-block-container .list-view-item-image:hover + .discount-item-text-container .list-view-item-name a,
    .news-block-container .list-view-item-name a:hover,
    .news-block-container .list-view-item-image:hover + .news-item-text-container .list-view-item-name a {
      text-decoration: underline;
    }

    .discount-item-text-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      height: 100%;
      order: 3;
    }
      .discount-block-container .list-view-item-button {
        order: 4;
      }


  .price-table-more-link {
    text-align: center;
    padding: 0px 0px 25px;
  }


  .price-table {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0px 0px 25px;
    position: relative;
  }
    .price-table-counter {
      counter-reset: price-table-group-counter;
    }
    .price-table > div + div {
      margin-top: 35px;
    }
      .price-table > div > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
      }
      .price-table-group {
        padding-bottom: 13px;
      }
        .price-table-counter .price-table-group {
          counter-reset: price-table-subheader-counter;
        }
        .price-table-group span {
          font-size: 30px;
          font-weight: 700;
          text-transform: uppercase;
        }
          .price-table-counter .price-table-group span:before {
            content: counter(price-table-group-counter) ". ";
            counter-increment: price-table-group-counter;
            font-weight: 400;
          }

      .price-table-head {
        background: #eaeaea;
      }

      .price-table-subheader {
        background: #fff;
        position: relative;
        z-index: 1;
      }
        .price-table-row + .price-table-subheader {
          margin-top: -1px;
        }
      .price-table-row {
        background: #fff;
      }
        .price-table-row.even {
          background: #f7f7f7;
        }
        .price-name,
        .price-price,
        .price-unit {
          font-size: 16px;
          padding: 5px 12px;
          flex-basis: 100%;
          flex-shrink: 4;
        }
        .price-name {
          padding-left: 30px;
        }
        .price-price {
          flex-shrink: 9;
        }
          .price-price > span {
            font-size: inherit;
            width: 0px;
            height: 0px;
            padding: 0px 0.12em 0px;
          }
        .price-unit,
        .price-unit + .price-price {
          flex-shrink: 11;
        }
        .price-unit,
        .price-price {
          text-align: center;
        }
          .price-table-head .price-name,
          .price-table-head .price-price,
          .price-table-head .price-unit {
            font-size: 26px;
            font-weight: 400;
            /*line-height: 1.1;*/
            padding-top: 10px;
            padding-bottom: 10px;
          }
          .price-table-subheader .price-name {
            color: #d10e0e;
            font-size: 18px;
            font-weight: 900;
            text-transform: uppercase;
            padding-top: 7px;
            padding-bottom: 7px;
            border-bottom: 1px solid #e8e8e8;
            /*padding: 5px 18px;
            text-align: center;*/
          }
            .price-table-counter .price-table-subheader .price-name:before {
              content: counter(price-table-group-counter) "." counter(price-table-subheader-counter) ". ";
              counter-increment: price-table-subheader-counter;
              font-weight: 500;
            }
            .price-table-row + .price-table-subheader .price-name {
              border-top: 1px solid #e8e8e8;
            }


  .main-price-container {
    /*margin: 0px 0px 25px;*/
    position: relative;
  }
    .main-price-tabs {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      /*font-size: 0px;
      padding: 0px 70px 0px 0px;*/
      position: relative;
      z-index: 1;
    }
      .main-price-tabs > a {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #eaeaea;
        height: 70px;
        padding: 0px 30px 0px 60px;
        cursor: pointer;
        position: relative;
      }
        .main-price-tabs > a.active {
          background: #d10e0e;
          z-index: 1;
        }
        .main-price-tabs > a:before,
        .main-price-tabs > a:after {
          content: "";
          position: absolute;
          top: 0px;
          /*bottom: 0px;*/
          width: 0px;
          height: 0px;
        }
        .main-price-tabs > a:after {
          left: 100%;
          border-top: 70px solid transparent;
          border-left: 70px solid #eaeaea;
        }
          .main-price-tabs > a.active:after {
            border-left-color: #d10e0e;
          }

        .main-price-tabs > a + a {
          padding-left: 35px;
          margin-left: 70px;
        }
          .main-price-tabs > a + a:before {
            right: 100%;
            border-top: 70px solid #eaeaea;
            border-left: 70px solid transparent;
          }
            .main-price-tabs > a + a.active:before {
              border-top-color: #d10e0e;
            }

        .main-price-tabs > a > * {
          font-size: 30px;
          /*font-weight: 500;*/
          text-transform: uppercase;
          white-space: nowrap;
          cursor: pointer;
        }
          .main-price-tabs > a.active > * {
            color: #fff;
          }


    .main-price-content > div:not(.active) {
      display: none;
    }
      .main-price-table-quick-links {
        background: #d10e0e;
        padding: 10px 30px 25px 15px;
        margin: 0px;
      }
        .main-price-table-quick-links a {
          color: #fff;
          font-weight: 500;
          border-bottom-color: transparent;
        }

        .main-price-table-quick-links > div:not(:last-child):after {
          color: #fff;
        }

        .main-price-table-quick-links a:hover,
        .main-price-table-quick-links:not(.selected) > div:first-child a,
        .main-price-table-quick-links > div.active a {
          border-bottom-color: #fff;
        }
        .main-price-table-quick-links:not(.selected) > div:first-child a,
        .main-price-table-quick-links > div.active a {
          font-weight: 600;
        }

      .main-price-content .price-table-container .price-table:not(.selected) > div:not(:first-child),
      .main-price-content .price-table-container .price-table.selected > div:not(.active),
      .main-price-content .price-table-group {
        display: none;
      }
        .main-price-content .price-table-container .price-table > div + div {
          margin-top: 0px;
        }


      .main-price-card-container,
      .main-price-table-container > .price-table-container:first-child {
        margin-top: 20px;
      }


  .service-page-group-name {
    font-size: 36px;
    font-weight: 900;
    line-height: 1.3;
    text-transform: uppercase;
    padding: 0px 0px 20px;
    text-align: left;
  }
    .service-block-container + .service-page-group-name {
      padding-top: 15px;
    }
    .service-page-group-name + .service-block-container + .service-block-container {
      padding-top: 20px;
    }


  /*.portfolio-info-block-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 0px 20px;
    margin: 0px 0px 0px -25px;
  }
    .portfolio-info-block-container > div {
      padding-left: 25px;
    }
      .portfolio-info-block-container > div img {
        content: "";
        height: 18px;
        margin-right: 8px;
        vertical-align: middle;
      }
      .portfolio-info-block-container > div span {
        font-size: 17px;
        vertical-align: middle;
      }*/


  /*.discount-page-image {
    height: 0px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: transparent;
    background-size: cover;
    padding: 20% 0px 0px;
    margin: 0px 0px 25px;
  }*/


  .faq-list {
    padding: 0px 0px 30px;
  }
    .faq-list > div {
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      padding: 19px 90px 20px 30px;
      position: relative;
      cursor: pointer;
    }
      .faq-list > div + div {
        margin-top: -1px;
      }

      .faq-list > div:before {
        content: "";
        background: url('/i/faq-plus.svg') no-repeat 50% 50% transparent;
        background-size: contain;
        position: absolute;
        top: 22px;
        right: 30px;
        width: 25px;
        height: 25px;
        z-index: 1;

        -webkit-transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
      }
        .faq-list > div.active:before {
          -webkit-transform: rotate(-135deg);
          -moz-transform: rotate(-135deg);
          -ms-transform: rotate(-135deg);
          -o-transform: rotate(-135deg);
          transform: rotate(-135deg);
        }

      .faq-list > div > div:first-child {
        font-size: 24px;
        line-height: 1.3;

        -webkit-transition: color 0.3s ease 0s;
        -moz-transition: color 0.3s ease 0s;
        -o-transition: color 0.3s ease 0s;
        transition: color 0.3s ease 0s;
      }
        .faq-list > div:hover > div:first-child,
        .faq-list > div.active > div:first-child {
          color: #d10e0e;
        }

      .faq-list > div > div + div {
        display: none;
        overflow: hidden;
      }
        .faq-list > div > div + div > div {
          font-size: 16px;
          font-weight: 500;
          border-left: 2px solid #d10e0e;
          padding-left: 18px;
          margin-top: 20px;
        }



/* Contact */
.contact-map-block-container {
  padding: 40px 0px 0px;
  margin: 0px 0px 25px;
  position: relative;
}
  .contact-map-placeholder {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
    .contact-map-placeholder:before {
      content: "Загрузка карты...";
      margin: 0px 0px 0px 435px;
    }

  .contact-map-container {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
  }

  .contact-info-container {
    width: 435px;
    padding: 35px 30px 30px;
    position: relative;
    z-index: 3;
  }
    .contact-info-container:before,
    .contact-info-container:after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      z-index: -1;
    }
    .contact-info-container:before {
      background: #d10e0e;
      left: 0px;
      right: 80px;
    }
    .contact-info-container:after {
      right: 0px;
      border-top: 80px solid transparent;
      border-left: 80px solid #d10e0e;
    }
      .contact-info-container > div + div {
        margin-top: 30px;
      }
        .contact-info-label span {
          display: block;
          color: #fff;
          font-size: 20px;
          font-weight: 900;
          line-height: 1.3;
          text-transform: uppercase;
          margin: 0px 0px 7px;
        }

        .contact-info-address,
        .contact-info-address *,
        .contact-info-phones a,
        .contact-info-emails a,
        .contact-info-notice-container,
        .contact-info-notice-container * {
          color: #fff;
        }

        .contact-info-phones a {
          font-size: 22px;
          font-weight: 600;
          line-height: 1.3;
          cursor: default;
        }

        .contact-info-emails a {
          font-weight: 600;
          line-height: 1.1;
          text-decoration: underline;
          cursor: pointer;
        }
          .contact-info-emails a:hover {
            text-decoration: none;
          }



/* Footer */
.footer {
  background: url('/i/footer-bg.jpg') bottom center repeat-y #eee;
  padding: 0px;
  margin: 0px auto;
  width: 1240px;
  position: relative;
  z-index: 1;
}
  .footer-callback-form {
    padding: 20px 30px 50px;
  }
    .footer-callback-form .form {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-content: flex-start;
      align-items: flex-start;
      margin: -20px 0px 0px -20px;
    }
      .footer-callback-form .form-row {
        width: 360px;
        margin: 20px 0px 0px 20px !important;
      }
        .footer-callback-form .form-row-time {
          width: 260px;
        }
        .footer-callback-form .form-label {
          display: none;
        }

      .footer-callback-form .form-buttons {
        width: 100%;
        margin: 40px 0px 0px 20px;
      }


  .footer-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    align-items: center;
    background: url('/i/footer-bg.jpg') bottom center no-repeat transparent;
    padding: 0px 60px 25px 0px;
    position: relative;
    overflow: hidden;
  }
    .footer-content:before,
    .footer-content:after {
      content: "";
      position: absolute;
      top: 0px;
      bottom: 0px;
      mix-blend-mode: multiply;
      opacity: 0.75;

      z-index: 0;
    }
    .footer-content:before {
      left: 0px;
      right: 210px;
      background: #6c0000;
    }
    .footer-content:after {
      right: 0px;
      border-top: 210px solid transparent;
      border-left: 210px solid #6c0000;
    }


    .footer-content > div {
      padding: 35px 0px 0px 35px;
      z-index: 2;
    }


    .footer-menu-container {
      width: 100%;
    }
      .footer-menu-container > ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px 120px 35px 35px;
        margin: 0px -60px 0px -35px;
        border-bottom: 2px solid #fff;
      }
        .footer-menu-container > ul > li + li > div,
        .footer-menu-container > ul > li + li > ul > li {
          padding-left: 90px;
        }
          .footer-menu-container > ul li > div > div > * {
            font-size: 30px;
            font-weight: 500;
            color: #fff;
            cursor: default;
            text-decoration: none;
            /*white-space: nowrap;*/
          }
            .footer-menu-container > ul li > div > div > a {
              cursor: pointer;
            }
              .footer-menu-container > ul li > div > div > a:hover,
              .footer-menu-container > ul li.active > div > div > a {
                text-decoration: underline;
              }
              .footer-menu-container > ul li.active > div > div > a {
                cursor: default;
              }

          .footer-menu-container > ul > li > ul > li > div {
            padding-top: 4px;
          }
            .footer-menu-container > ul > li > ul > li > div > div > * {
              font-size: 20px;
              font-weight: 500;
            }

        .footer-menu-container .icn-menu-service1 > ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: flex-start;
        }
          .footer-menu-container .icn-menu-service1 > ul > li {
            width: 31%;
          }


    .footer-logo a,
    .footer-logo a * {
      cursor: pointer;
    }


    .footer-phones > div {
      padding-bottom: 4px;
      text-align: center;
    }
        .footer-phones a {
          color: #fff;
          font-size: 29px;
          font-weight: 700;
          cursor: default;
          text-decoration: none;
        }

      .footer-worktime,
      .footer-worktime * {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
      }


    .footer-address-container,
    .footer-emails-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }
      .footer-address-container *,
      .footer-emails-container * {
        color: #fff;
        font-weight: 500;
      }

      .footer-address-label,
      .footer-emails-label {
        font-weight: 700;
        padding-right: 8px;
      }

      .footer-emails {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
        .footer-emails a {
          cursor: pointer;
          text-decoration: none;
        }
          .footer-emails a:hover {
            text-decoration: underline;
          }


    .footer-copyrights {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
      .footer-copyrights * {
        font-size: 15px;
      }
      .footer-copyright {
        color: #fff;
        font-weight: 600;
      }